import { useEffect, useState } from 'react';
import swal from "sweetalert";
import './App.css';
import axios from "axios";

import droneTop from "./assets/images/drone-top.png";
import droneFront from "./assets/images/drone-front.png";
import droneLogin from "./assets/images/drone-login.png";

function App() {
  const [login, setlogin] = useState(null);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [frame, setframe] = useState(false);
  const [left, setleft] = useState(0);
  const [right, setright] = useState(0);
  const [top, settop] = useState(0);
  const [ground, setground] = useState(0);
  const [front, setfront] = useState(0);
  const [back, setback] = useState(0);
  const [lat, setlat] = useState(0);
  const [long, setlong] = useState(0);
  const [date, setdate] = useState(0);
  const [time, settime] = useState(0);
  const [people, setpeople] = useState(0);
  const [ip, setip] = useState(null);

  useEffect(()=>{
    if(localStorage.getItem("droneuser")){
      if(localStorage.getItem("droneuser") == "loggedin"){
        setlogin(1);
        setInterval(()=>{
          getData();
        },100);
      }
    }
  },[]);

  const getData = ()=>{
    axios.get("https://drone.tdpvista.co.in/getdata.php").then((res)=>{
      setleft(res.data.leftval);
      setright(res.data.rightval);
      settop(res.data.topval);
      setground(res.data.groundval);
      setfront(res.data.frontval);
      setback(res.data.backval);
      setdate(res.data.dateval);
      settime(res.data.timeval);
      setlat(res.data.latval);
      setlong(res.data.longval);
      setpeople(res.data.peopleval);
      if(res.data.ipval != ip){
        setip(res.data.ipval);
        console.log(res.data.ipval);
      }
    }).catch((err)=>{
      console.log(err);
    });
  }

  const loginuser = () => {
    if (email == "drone@tdpvista.co.in" && password == "TestDrone@#23") {
      swal("Congratulations!", "Logged In Successfully", "success");
      setlogin(1);
      localStorage.setItem("droneuser","loggedin");
    } else {
      swal("Error!", "Invalid Credentials", "error");
    }
  }

  const logout = () => {
    setlogin(null);
    swal("Done", "Logged Out Successfully", "success");
    localStorage.removeItem("droneuser");
  }

  const handleframe = ()=>{
    setframe(!frame);
  }

  return (
    <>
      {login ?
        <>
          <div className='container mx-auto flex items-center h-screen'>
            <div className='flex items-center'>
              <div className={`w-${frame?"1/4":"1/2"}`}>
                <div className='flex flex-wrap items-center'>
                  <div className='w-1/6'>
                    <div className='w-full bg-blue-700 text-white font-bold rounded-lg'>
                      <h2 className='font-bold text-lg'>Left</h2>
                      <p>{left} Meters</p>
                    </div>
                  </div>
                  <div className='w-4/6'>
                    <div className='flex justify-center'>
                      <div className='w-fit px-8 bg-blue-700 text-white font-bold rounded-lg'>
                        <h2 className='font-bold text-lg'>Front</h2>
                        <p>{front} Meters</p>
                      </div>
                    </div>
                    <img src={droneTop} style={{ width: "100%", transform:"rotate(180deg)" }} />
                    <div className='flex justify-center'>
                      <div className='w-fit px-8 bg-blue-700 text-white font-bold rounded-lg'>
                        <h2 className='font-bold text-lg'>Back</h2>
                        <p>{back} Meters</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-1/6'>
                    <div className='w-full bg-blue-700 text-white font-bold rounded-lg'>
                      <h2 className='font-bold text-lg'>Right</h2>
                      <p>{right} Meters</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`w-${frame?"1/4":"1/2"}`}>
                <div className='flex flex-wrap'>
                  <div className='w-full'>
                    <div className='flex justify-center'>
                      <div className='w-fit px-8 bg-blue-700 text-white font-bold rounded-lg'>
                        <h2 className='font-bold text-lg'>Top</h2>
                        <p>{top} Meters</p>
                      </div>
                    </div>
                    <img src={droneFront} style={{ width: "100%" }} />
                    <div className='flex justify-center'>
                      <div className='w-fit px-8 bg-blue-700 text-white font-bold rounded-lg'>
                        <h2 className='font-bold text-lg'>Ground</h2>
                        <p>{ground} Meters</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`w-${frame?"full flex items-center justify-center":"1/2 hidden"}`}>
                {ip?
                  <iframe style={{ width: "100%", aspectRatio: "16/9" }} src={`http://${ip}`}></iframe>
                :
                  <h1 className='text-3xl font-bold text-center'>Camera Not Started</h1>
                }
              </div>
            </div>
          </div>
          <div className='fixed top-10 flex justify-center w-screen'>
            <div className='bg-white rounded-lg shadow-xl border p-4 font-bold items-center'>
              <div>
                {/* <div className='text-center mb-2'>
                  Found <span className='font-bold'>{people}</span> People Nearby
                </div> */}
              </div>
              <div className='flex'>
                <div className='bg-blue-700 text-white p-4 rounded-lg text-left mr-4'>
                  <h2 className='font-bold'>Location</h2>
                  <p>Lat: {lat}</p>
                  <p>Long: {long}</p>
                </div>
                <div className='bg-blue-700 text-white p-4 rounded-lg text-left mr-4'>
                  <h2 className='font-bold'>Reporting</h2>
                  <p>Date: {date}</p>
                  <p>Time: {time}</p>
                </div>
                <div>
                  <button className='bg-red-700 text-white rounded-lg p-2 w-full font-bold mb-2' onClick={logout}>Log Out</button>
                  <button className='bg-blue-700 text-white rounded-lg p-2 w-full font-bold' onClick={handleframe}>Camera</button>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <div className='w-screen h-screen flex justify-center items-center'>
            <div className='card w-1/4'>
              <img src={droneLogin} style={{ width: "100%" }} />
              <label class="block mb-2" style={{ textAlign: "left" }}>
                <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                  Email
                </span>
                <input type="email" name="email" value={email} onChange={(e) => { setemail(e.target.value) }} class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Enter Email Here" />
              </label>
              <label class="block" style={{ textAlign: "left" }}>
                <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                  Password
                </span>
                <input type="password" name="password" value={password} onChange={(e) => { setpassword(e.target.value) }} class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Enter Password Here" />
              </label>
              <div className='mt-2'>
                <button className='bg-blue-700 text-white rounded-lg p-2 w-full font-bold' onClick={loginuser}>Login</button>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default App;
